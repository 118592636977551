import React from 'react';
import person from '../images/person.png';

const Testimonials = ({ smallTitle, title, subtitle, testimonials }) => {
  return (
    <div className='w3-content main-content'>
      <section className='testimonials-section w3-padding-24'>
        <div className='w3-padding w3-margin-bottom'>
          <div className='small-title'>{smallTitle}</div>
          <div className='title w3-margin-bottom' dangerouslySetInnerHTML={{ __html: title }} />
          <div className='description w3-margin-bottom' dangerouslySetInnerHTML={{ __html: subtitle }} />
        </div>
        <div className="">
          <div className="w3-row-padding w3-margin-top testimonials-container">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="w3-third w3-margin-bottom testimonial-card">
                <div className="w3-card w3-padding animate-card-border card-content">
                  <div>
                    <p className='title'>{testimonial.title}</p>
                    <p className='description'>{testimonial.description}</p>
                  </div>
                  <div className='person-container'>
                    <img src={person} alt='Person' />
                    <div className='name-container'>
                      <span className='name'>{testimonial.name}</span>
                      <span className='designation'>{testimonial.designation}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
