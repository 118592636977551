import React from 'react';

const Logo = ({ name }) => (
  <div className="logo-container">
    <img src={`images/${name}`} alt={name} className="logo-image" />
  </div>
);

const Trusted = ({ title, logos }) => {
  return (
    <div className='w3-content main-content'>
    <section className="trusted-section">
      <div className="trusted-container">
        <h2 className="title">{title}</h2>
        <div className="logo-grid">
          {logos.map((logo, index) => (
            <Logo key={index} name={logo} />
          ))}
        </div>
      </div>
    </section>
    </div>
  );
};

export default Trusted;