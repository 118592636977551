import React, { useState } from 'react';
import Logo from '../images/Logo.png';
import rightCaret from '../images/right-caret.png';

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const MenuItems = ({ className, itemClass, onClick }) => (
    <div className={className}>
      <a href="#home" className={`w3-button w3-hover-none ${itemClass}`} onClick={onClick}>Home</a>
      <a href="#trusted" className={`w3-button w3-hover-none ${itemClass}`} onClick={onClick}>Trusted</a>
      <a href="#features" className={`w3-button w3-hover-none ${itemClass}`} onClick={onClick}>Features</a>
      <a href="#testimonials" className={`w3-button w3-hover-none ${itemClass}`} onClick={onClick}>Testimonials</a>
      <a href="#pricing" className={`w3-button w3-hover-none ${itemClass}`} onClick={onClick}>Pricing</a>
      <a href="https://vertex-app.dev.ceegees-inc.com" className={`w3-button w3-hover-none active ${itemClass}`} onClick={onClick}>Sign in <img src={rightCaret} alt="Right Caret" /></a>
    </div>
  );

  return (
    <div className="w3-top w3-bar top-nav">
      <div className='w3-content main-content'>
        <div className="w3-bar w3-padding">
          <div className="w3-bar-item">
            <img src={Logo} alt="Logo" />
          </div>
          <MenuItems className="w3-bar-item w3-right w3-hide-small" itemClass="" />
          <div className="w3-bar-item w3-right w3-hide-large w3-hide-medium">
            <div onClick={toggleDrawer} className="w3-hover-none drawer-btn hamburger cursor-pointer">&#9776;</div>
          </div>
        </div>
        <div className={`w3-sidebar w3-bar-block w3-animate-right ${isDrawerOpen ? 'w3-show' : 'w3-hide'}`} style={{ right: 0 }}>
          <div onClick={toggleDrawer} className="w3-bar-item w3-large w3-right-align"><span className='drawer-btn cursor-pointer'>&times;</span></div>
          <MenuItems className="w3-bar-block" itemClass="w3-bar-item" onClick={closeDrawer} />
        </div>
      </div>
    </div>
  );
};

export default Header;
