import React from 'react';
import BookDemo from './BookDemo';
import Logo from '../images/Logo.png'

const Footer = () => (
  <footer className="w3-padding-64 w3-display-container">
    <div className='w3-content main-content'>
      <div className="w3-row-padding">
        <div className="w3-col s12 m2">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="w3-col s12 m2">
          <ul className="w3-ul">
            <li className='nav-link-heading'>Company</li>
            <li><a href="#" className='w3-margin-bottom'>Features</a></li>
            <li><a href="#" className='w3-margin-bottom'>Pricing</a></li>
            <li><a href="#" className='w3-margin-bottom'>Docs</a></li>
            <li><a href="#" className='w3-margin-bottom'>Contact</a></li>
          </ul>
        </div>

        <div className="w3-col s12 m2">
          <ul className="w3-ul">
            <li className='nav-link-heading'>Others</li>
            <li><a href="#" className='w3-margin-bottom'>Blogs</a></li>
            <li><a href="#" className='w3-margin-bottom'>Doc</a></li>
            <li><a href="#" className='w3-margin-bottom'>Security</a></li>
            <li><a href="#" className='w3-margin-bottom'>FAQ</a></li>
          </ul>
        </div>

        <div className="w3-col s12 m2">
          <ul className="w3-ul">
            <li className='nav-link-heading'>Legal</li>
            <li><a href="#" className='w3-margin-bottom'>Terms</a></li>
            <li><a href="#" className='w3-margin-bottom'>Privacy</a></li>
            <li><a href="#" className='w3-margin-bottom'>Policy</a></li>
          </ul>
        </div>

        <div className="w3-col s12 m4">
          <BookDemo />
        </div>
      </div>
    </div>
  </footer>

);

export default Footer;
