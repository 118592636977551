import React from 'react';
import whiteTick from '../images/white-tick.png';
import darkTick from '../images/dark-tick.png';

const Pricing = ({ title, plans }) => (
  <div className='w3-content main-content'>
    <section className='w3-padding-64'>
      <div className="">
        <h2 className="heading">{title}</h2>
        <div className="w3-row-padding w3-margin-top">
          {plans.map((plan, index) => (
            <div key={index} className={`w3-col m4 w3-margin-bottom`}>
              <div className={`w3-display-container w3-card ${plan.highlight ? 'active-card' : 'w3-white'} w3-left-align w3-content`} style={{ maxWidth: '420px' }}>
                {plan.highlight && <div className='w3-display-topmiddle worth-it'>Worth it!</div>}
                <div className={`w3-container ${plan.highlight ? 'w3-margin-top' : ''} w3-padding-16`}>
                  <h3 className={`price-type ${index === 2 ? 'multi-owner' : index === 1 ? 'single-owner' : 'free-trial'}`}>{plan.title}</h3>
                  <h4 className='price-amount'>{plan.price}<span className='price-duration'>/{plan.duration}</span></h4>
                </div>
                <div className='w3-container'>
                  <div className='sub-heading'>Features</div>
                  <ul className="w3-ul w3-padding-16">
                    {plan.features.map((feature, i) => (
                      <li key={i} className='no-border'>
                        <img src={plan.highlight ? whiteTick : darkTick} style={{ marginRight: 6 }} />
                        <span className={`price-feature-item ${plan.highlight ? 'active' : ''}`}>
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w3-container w3-padding-16 price-btn-container">
                  <button className={`w3-button w3-round-xxlarge ${plan.highlight ? 'active' : ''} w3-padding-large w3-block secondary-btn`}>{plan.cta}</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  </div>
);

export default Pricing;
