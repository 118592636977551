

import React, { useEffect } from 'react';
import placeholderImage from '../images/feature-placholder.png';

const Features = ({ featureSections }) => {

  useEffect(() => {
    const handleScroll = () => {
      const parallaxContainers = document.querySelectorAll('.parallax-container');

      parallaxContainers.forEach(container => {
        const parallaxContent = container.querySelector('.parallax-content');
        let scrollPosition = window.pageYOffset;
        let containerOffsetTop = container.offsetTop;
        let offset = (scrollPosition - containerOffsetTop) * 1;

        if (scrollPosition >= containerOffsetTop) {
          parallaxContent.style.transform = `translate(-50%, calc(-50% + ${offset}px))`;
        } else {
          parallaxContent.style.transform = 'translate(-50%, -50%)';
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='w3-content main-content'>
      {featureSections.map((feature, index) => (
        <div key={index} className="parallax-container feature-item">
          <div className="w3-row-padding parallax-content">
            <div className='w3-col m6'>
              <div className='small-title'>{feature.smallTitle}</div>
              <h3 className="title">{feature.title}</h3>
              <p className='description'>{feature.content}</p>
            </div>
            <div className='w3-col m6'>
              <img src={placeholderImage} className='w3-image' alt={feature.title} />
            </div>
          </div>
        </div>
      ))}


    </div>
  );
};

export default Features;