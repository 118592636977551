import React from 'react';
import fb from '../images/fb.png'
import linkedIn from '../images/linkedin.png'
import instagram from '../images/instagram.png'
import xTwitter from '../images/x_twitter.png'


const BookDemo = () => {
    return (
        <div className="w3-container w3-padding-16 w3-white w3-round-xlarge book-demo-card">
            <div className='top-text w3-margin-top w3-margin-bottom'>Book your demo <span>and set up within an hour.*</span></div>
            <div className="w3-row w3-margin-top w3-margin-bottom w3-display-container demo">
                <input
                    type="text"
                    className="w3-input w3-border w3-round-xxlarge"
                    placeholder='your work email here'
                />
                <button className="w3-button primary-btn w3-round-xxlarge w3-margin-left w3-margin-top demo-btn">
                    Book demo
                </button>
            </div>
            <div className="w3-margin-top w3-margin-bottom bottom-text">
                People what discussion join flesh businesses winning illustration
                dog. Lean boil get skulls land any but. Heads-up!
            </div>
            <div className='w3-margin-top social-icons'>
                <img src={fb} />
                <img src={linkedIn} />
                <img src={instagram} />
                <img src={xTwitter} />
            </div>
        </div>
    );
};

export default BookDemo;
