import React, { useState, useEffect, useRef } from "react";
import front from '../images/front.png';
import mid from '../images/mid.png';
import back from '../images/back.png';

const Carousel = ({ showArrows = false }) => {
  const images = [
    front,
    mid,
    back
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardCount = images.length;
  const intervalDuration = 2000;
  const [displaySlides, setDisplaySlides] = useState([images[0]]);

  const sliderRef = useRef({
    currentIndex: currentIndex,
    displaySlides: displaySlides
  });

  useEffect(() => {
    sliderRef.currentIndex = currentIndex;
    sliderRef.displaySlides = displaySlides;
  }, [currentIndex, displaySlides]);

  useEffect(() => {
    const interval = setInterval(() => {
      let nextIndex = (sliderRef.currentIndex + 1) % cardCount;
      if (sliderRef.displaySlides.length === images.length) {
        let newSlides = [];
        newSlides.push(images[0]);
        setDisplaySlides(newSlides);
        setCurrentIndex(0);
        return;
      }
      let nextSlides = sliderRef.displaySlides;
      nextSlides.push(images[nextIndex]);
      setDisplaySlides(nextSlides);
      setCurrentIndex(nextIndex);
    }, intervalDuration);

    return () => clearInterval(interval);
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + cardCount) % cardCount);
  };

  const handleNextClick = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % cardCount);
  };

  return (
    <div className="slideshow">
      <div className="card-container">
        {displaySlides.map((image, i) => (
          <div
            key={i}
            className={`card`}
            style={{
              zIndex: i,
              transform: `translateX(-50%) translateY(${(-(displaySlides.length * 20)) - 50}px)`, 
              width: `${80+(((i+1)/displaySlides.length)*10)}%`,
              marginTop: `${100-(-i*30)}px`,
            }}
          >
            <img src={image} alt={`Card ${i + 1}`} />
          </div>
        ))}
      </div>
      {showArrows && (
        <>
          <div className="arrow arrow-right" onClick={handleNextClick}>
            <svg viewBox="0 0 5.99 9.86">
              <polyline points="5.24 9.11 1.06 4.93 5.24 0.75" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5"></polyline>
            </svg>
          </div>
          <div className="arrow arrow-left" onClick={handlePrevClick}>
            <svg viewBox="0 0 5.99 9.86">
              <polyline points="5.24 9.11 1.06 4.93 5.24 0.75" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5"></polyline>
            </svg>
          </div>
        </>
      )}
    </div>
  );
};

export default Carousel;
