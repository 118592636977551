import React from 'react';
import './App.scss';
import content from './content.json';
import Home from './components/Home';
import Trusted from './components/Trusted';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import Pricing from './components/Pricing';
import Header from './components/Header';
import Footer from './components/Footer';


function App() {
  const { sections } = content;
  const pricingSection = sections.find(section => section.id === 'pricing');
  const updatedPlans = pricingSection.plans.map(plan => ({
    ...plan,
    highlight: plan.title === "Single Owner",
    cta: plan.title === "Multiple Owners" ? "Contact Us" :  plan.title === "Single Owner" ? "Purchase" : "Try for free now"
  }));
  return (
    <>
      <Header />
      <div className="home w3-container w3-center w3-padding-64" id="home">
        <Home {...sections.find(section => section.id === 'home')} />
      </div>
      <div className="trusted w3-container w3-center w3-padding-64" id="trusted">
        <Trusted {...sections.find(section => section.id === 'trusted')} />
      </div>
      <div className="features w3-container w3-center w3-padding-64" id="features">
          <Features {...sections.find(section => section.id === 'features')} />
      </div>
      <div className="testimonials w3-container w3-center w3-padding-64" id="testimonials">
        <Testimonials {...sections.find(section => section.id === 'testimonials')} />
      </div>
      <div className="pricing w3-container w3-center w3-padding-64" id="pricing">
        <Pricing {...sections.find(section => section.id === 'pricing')} plans={updatedPlans} />
      </div>

      <div className="footer-section">
        <div className='w3-row'>
          <Footer />
        </div>
        <div className='w3-row w3-padding-16 foot-text'>
          <div className='w3-col m3 w3-hide-small'>&nbsp;</div>
          <div className='w3-col m6 s12 w3-center'>
            <div className='w3-padding'>
              Made with ❤️ in India
            </div>
          </div>
          <div className='w3-col  m3 s12 copy-right'>
            <div className='w3-padding'>
              Vertex.com © 2024. All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;