import React from 'react';
import Carousel from './Carousel';


const Home = ({ title, subtitle, smallTitle }) => {
  return (
    <section className="w3-center">
      <div className='w3-content main-content'>
        <div className="w3-row w3-margin-top w3-margin-bottom">
          <p className='small-title'>{smallTitle}</p>
          <h1 className="jumbo-text" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="jumbo-desc">{subtitle}</p>
          <div className="w3-row demo">
            <div className="w3-col m3 w3-hide-small">&nbsp;</div>
            <div className='w3-col s12 m6 w3-display-container'>
              <input
                type="text"
                className="w3-input w3-border w3-round-xxlarge"
                placeholder='your work email here'
              />
              <button className="w3-button primary-btn w3-round-xxlarge w3-margin-left w3-margin-top demo-btn">
                Book demo
              </button>
            </div>
            <div className="w3-col m3 w3-hide-small">&nbsp;</div>
          </div>
        </div>
        <div className='slideshow-container'>
          <Carousel />
        </div>
      </div>
    </section>
  );
};

export default Home;
